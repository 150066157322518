<template>
    <section class="section__body">

        <!--- Navigation --->
        <GeoitemsList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            <ul class="list mb__12">
                <li class="list__item item item_view_head item_type_collapses-group">
                    <h4 class="list__title">Geoitems (125)</h4>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 1</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 2</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 3</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 4</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 1</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 2</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 3</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 4</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 1</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 2</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 3</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
                <li class="list__item">
                    <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__geozone_circle" /></span> </span>
                    <h4 class="list__title">Geofence name 4</h4>
                    <div class="list__button-group">
                        <button class="button button_view_icon button_theme_base mtb__-4" >
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit" /></span> </span>
                        </button>
                        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete" /></span> </span>
                        </button>
                    </div>
                </li>
            </ul>
        </section>

    </section>
</template>

<script>
    import GeoitemsList_navigation from "./GeoitemsList_navigation.vue";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "GeoitemsList",
        components: {
            GeoitemsList_navigation,
        },
        data() {
            return {
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ])
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
        },
        updated() {
            //console.log('GeoitemsList updated')
        },
        mounted() {
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>